import { LoaderFunction, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { locationDetailQuery } from './queries';
import { Avatar, Box, Heading, Paragraph, Text } from 'grommet';
import { QueryClient, useQuery } from '@tanstack/react-query';

export const loader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ params }) => {
    invariant(params.id);
    const q = locationDetailQuery(params.id);
    return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
  };

export default function Location() {
  const params = useParams();
  invariant(params.id);

  const { data } = useQuery(locationDetailQuery(params.id));
  return (
    data && (
      <Box direction="column">
        <Box direction="row" gap="medium" border="bottom" alignContent="center">
          <Avatar src="//s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80" />
          <Box direction="column">
            <Heading level="3" margin="none">
              {data.name}
            </Heading>
            <Heading level="5" margin="none">
              {data.address.line}
            </Heading>
            <Box direction="row">
              <Text margin={{ right: 'xsmall' }}>{data.address.city + ', '}</Text>
              <Text>{data.address.state}</Text>
            </Box>
          </Box>
        </Box>
        <Box>
          <Paragraph>Here are some interesting things to know</Paragraph>
        </Box>
      </Box>
    )
  );
}
