import { createContext, useCallback, useState } from "react";
import { Banner } from "./banner";

export type BannerContextProps = {
    initial?: Banner[];
    children?: React.ReactNode
}

export type BannerContextType = {
    banners: Banner[];
    addBanner: (banner: Banner) => void;
    removeBanner: (id: string) => void;
}

const BannerContext = createContext<BannerContextType | null>(null);

export default BannerContext;

export const BannerContextProvider: React.FC<BannerContextProps> = ({initial, children}) => {

    const [banners, setBanners] = useState<Banner[]>(initial ?? []);

    const addBanner = useCallback((banner: Banner) => {
        setBanners(banners => [...banners, banner])
    }, [setBanners]);

    const removeBanner = useCallback((id: string) => {
        return setBanners(banners => banners.filter((b) => b.id !== id))
    }, [setBanners])

    return(
        <BannerContext.Provider value={{banners, addBanner, removeBanner}}>
            {children}
        </BannerContext.Provider>
    )
}