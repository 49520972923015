import './App.css';
import { RouterProvider } from 'react-router-dom';
import { Index } from './pages';
import { SpeakerPage, loader as speakerLoader } from './pages/SpeakerPage';
import Speaker, { loader as speakerDetailLoader } from './pages/SpeakerDetailPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocationPage, loader as locationLoader } from './pages/LocationPage';
import Location, { loader as locationDetailLoader } from './pages/LocationDetailPage';
import { SermonListPage, loader as sermonLoader } from './pages/SermonListPage';
import { SermonPage, loader as sermonDetailLoader } from './pages/SermonPage';
import { createSentryBrowserRouter } from './lib/sentry';
import { HomePage, loader as homePageLoader } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});
const router = createSentryBrowserRouter([
  {
    path: '/',
    element: <Index />,
    children: [
      {
        path: '/',
        element: <HomePage />,
        loader: homePageLoader(queryClient),
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/speakers',
        element: <SpeakerPage />,
        loader: speakerLoader(queryClient),
        children: [
          {
            path: '/speakers/details/:id',
            element: <Speaker />,
            loader: speakerDetailLoader(queryClient),
          },
        ],
      },
      {
        path: '/locations',
        element: <LocationPage />,
        loader: locationLoader(queryClient),
        children: [
          {
            path: '/locations/details/:id',
            element: <Location />,
            loader: locationDetailLoader(queryClient),
          },
        ],
      },
      {
        path: '/sermons',
        element: <SermonListPage />,
        loader: sermonLoader(queryClient),
        children: [
          {
            path: '/sermons/:id',
            element: <SermonPage />,
            loader: sermonDetailLoader(queryClient),
          },
        ],
      },
      {
        path: '/sermon/:id',
        element: <SermonPage />,
        loader: sermonDetailLoader(queryClient),
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}></RouterProvider>
    </QueryClientProvider>
  );
}

export default App;
