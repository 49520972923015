import { AppLayout } from '../components/AppLayout';
import { Outlet } from 'react-router-dom';

export function Index() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}
