import { motion } from "framer-motion";
import { Box, Button, Text } from "grommet";
import { Alert } from "grommet-icons";

export type AlertErrorProps = {
    message: string;
    retry?: () => void;
}

export function AlertError({message, retry}: AlertErrorProps) {
    return (
        <motion.div initial={{opacity: 0.0}} animate={{opacity: 1.0}} transition={{duration: 1.5}}>
        <Box direction='column' align="center" fill={true} align-content="center" pad={{top: 'medium'}}>
            <Alert size="large" color="status-critical" />
            <Text>
                {message}
            </Text>
            {retry && <Button primary onClick={retry} label='Retry' />
            }
        </Box>
        </motion.div>
    )
}