import { Box } from "grommet";
import { BackTen, ForwardTen, PauseFill, PlayFill } from "grommet-icons";
import { Duration } from "luxon";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export interface ControlProps {
    audioRef: RefObject<HTMLAudioElement>;
    onUpdate: (time: Duration) => void;
    onSet: (time: Duration) => void;
    autoPlay: boolean;
}

export function Controls({audioRef, onUpdate, onSet, autoPlay}: ControlProps) {

    const playAnimationRef = useRef(0);

    const [isPlaying, setIsPlaying] = useState(autoPlay);

    const repeat = useCallback(() => {
        // Make sure that we request a callback before manually updating the playhead
        if (playAnimationRef.current) {
            playAnimationRef.current = requestAnimationFrame(repeat);
        }
        if (audioRef.current) {
            // This doesn't seem very efficient, but I don't like exposing time as a number
            onUpdate(Duration.fromObject({seconds: audioRef.current.currentTime}));
        };
    }, [audioRef, playAnimationRef]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current?.play();
        } else {
            audioRef.current?.pause();
        }
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [isPlaying, audioRef, repeat]);

    const updateInSeconds = (time: number) => {
        if (audioRef.current) {
            onSet(Duration.fromObject({seconds: audioRef.current.currentTime + time}))
        }
    };

    return <Box direction="row" gap="small">
        <BackTen onClick={() => updateInSeconds(-10)} />
        { isPlaying ? <PauseFill onClick={() => setIsPlaying(false)}/> : <PlayFill onClick={() => setIsPlaying(true)} />}
        <ForwardTen onClick={() => updateInSeconds(10)}/>
    </Box>;
}