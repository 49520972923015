import { Speaker } from '../models/speaker';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { QueryClient } from '@tanstack/react-query';
import { speakerListQuery } from './queries';
import { EntityPage } from '../components/EntityPage';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const loader = (queryClient: QueryClient) => async () => {
  const q = speakerListQuery();
  return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
};

export function SpeakerPage() {
  const navigate = useNavigate();

  const [columnDefs, _] = useState<ColDef<Speaker>[]>([
    { field: 'firstName', filter: true },
    { field: 'middleName', filter: false, sortable: false },
    { field: 'lastName', filter: true },
  ]);

  const rowClicked = (event: RowClickedEvent<Speaker>) => {
    if (event.data) {
      navigate(`/speakers/details/${event.data.id}`);
    }
  };

  return EntityPage<Speaker>({
    title: 'Speakers',
    clickHandler: rowClicked,
    columns: columnDefs,
    query: speakerListQuery(),
  });
}
