import { z } from "zod";
import { speakerSchema } from "./speaker";
import { churchSchema } from "./church";

export const sermonSchema = z.object({
    id: z.string(),
    title: z.string(),
    delivered: z.string().optional(),
});

export const sermonsSchema = z.array(sermonSchema);

export type Sermon = z.infer<typeof sermonSchema>;

export const sermonTableRowSchema = z.object({
    id: z.string(),
    title: z.string(),
    delivered: z.string().optional(),
    deliveredBy: speakerSchema.optional(),
    deliveredAt: churchSchema.optional()
});

export const sermonTableRowsSchema = z.array(sermonTableRowSchema);

export type SermonTableRow = z.infer<typeof sermonTableRowSchema>;