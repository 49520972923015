import { Box, Heading } from "grommet";
import { Duration } from "luxon";
import { Media } from "../../models/media";
import { mediaTranscriptDocumentQuery } from "../../pages/queries";
import { useSuspenseQuery } from "@suspensive/react-query";
import { PhraseView } from "./PhraseView";

export interface TranscriptViewProps {
    playhead?: Duration
    media: Media
}

export function TranscriptView({playhead, media}: TranscriptViewProps) {
    const { data } = useSuspenseQuery(mediaTranscriptDocumentQuery(media.id))

    const ms = playhead?.shiftTo("milliseconds").milliseconds ?? 0;
    

    return(
        <Box margin={{top: 'large'}} align="center">
        <Heading level="4" margin="none">
              Transcript
            </Heading>
            {data.phrases.map((phrase, idx) => {
                return <PhraseView key={idx} playheadMs={ms} phrase={phrase} />
            })}
        </Box>
    )
}