import { Button, Card, CardBody, CardFooter, CardHeader, Text } from 'grommet';
import { CirclePlay } from 'grommet-icons';
import { sermonDetailQuery, sermonSpeakerQuery } from '../../pages/queries';
import { useSuspenseQueries } from '@suspensive/react-query';
import { useNavigate } from 'react-router-dom';

export type SermonCardProps = {
  id: string;
};

export function SermonCard({ id }: SermonCardProps) {
  const navigate = useNavigate();
  const [{ data: sermon }, { data: speaker }] = useSuspenseQueries({
    queries: [sermonDetailQuery(id), sermonSpeakerQuery(id)],
  });

  return (
    <Card height="medium" width="small" background="light-1" margin={{ top: 'medium' }}>
      <CardHeader pad='small' height='xsmall' align='top'>
        <Text weight="bold">{sermon.title}</Text>
      </CardHeader>
      <CardBody pad={{top: 'none', left: 'medium', right: 'medium'}} alignContent='top'>
        <Text>{speaker[0].firstName + ' ' + speaker[0].lastName}</Text>
      </CardBody>
      <CardFooter direction="row" pad={{ horizontal: 'xsmall' }} background="light-2" justify="end">
        <Button
          pad={{ right: 'xsmall', bottom: 'small' }}
          icon={<CirclePlay size="large" color="brand" />}
          onClick={() => navigate(`/sermon/${id}?autoPlay=true`)}
        />
      </CardFooter>
    </Card>
  );
}
