import { Phrase } from "../../models/transcript";
import { Box, Text } from "grommet";
import { useMemo } from "react";

export interface PhraseViewProps {
    playheadMs: number,
    phrase: Phrase
}

export function PhraseView({playheadMs: playhead, phrase}: PhraseViewProps) {

    const [start, end] = useMemo(() => {
        return [phrase.range.start, phrase.range.end]
    }, [phrase]);

    const color = playhead > end ? "gray" : "black";

    const bold = (playhead >= start && playhead < end) ? "bolder": "normal";


    return (
        <Box>
        <Text weight={bold} color={color}>{phrase.text}</Text>
        </Box>
    )
}