import { sermonMediaQuery } from '../../pages/queries';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';
import { Media } from '../../models/media';
import { Duration } from 'luxon';
import { useSuspenseQuery } from '@suspensive/react-query';
import { Select, Text } from 'grommet';
import { useEffect } from 'react';

export interface MediaListProps {
  id: string;
  onSelect: (media: Media) => void;
  onPlayheadUpdate: (playhead: Duration) => void;
  media?: Media;
  autoPlay: boolean;
}

const renderMediaSelection = (option: Media) => {
  return (
    <Text>
      {option.format} - {option.duration.toHuman()}
    </Text>
  );
};

export function MediaList({ id, onSelect, media, onPlayheadUpdate, autoPlay }: MediaListProps) {
  const { data } = useSuspenseQuery(sermonMediaQuery(id));

  useEffect(() => {
    if (data.length == 1) {
      onSelect(data[0]);
    }
  }, [data]);

  return (
    <>
      {data.length > 1 && (
        <Select
          options={data}
          value={media}
          valueLabel={renderMediaSelection}
          onChange={({ option }) => onSelect(option)}
          children={renderMediaSelection}
        />
      )}
      <AudioPlayer autoPlay={autoPlay} audio={media} onPlayheadUpdate={onPlayheadUpdate} />
    </>
  );
}
