import { z } from "zod";

export const transcriptSchema = z.object({
    id: z.string(),
    blob: z.string().optional(),
    media: z.string(),
    sermon: z.string()
})

export const transcriptsSchema = z.array(transcriptSchema);

export type Transcript = z.infer<typeof transcriptSchema>;


export const phraseSchema = z.object({
    range: z.object({
        start: z.number(),
        end: z.number(),
        resolution: z.string()
    }),
    text: z.string(),
    speaker: z.string().nullable()
});

export type Phrase = z.infer<typeof phraseSchema>

export const transcriptDocumentSchema = z.object({
    duration: z.string(),
    phrases: z.array(phraseSchema)
});

export type TranscriptDocument = z.infer<typeof transcriptDocumentSchema>;