import { PageContent, Paragraph } from "grommet";
import Page from "../components/Page";


export function AboutPage() {

    return (
        <Page title="About">
            <PageContent>
                <Paragraph>
                    The Centre for Religious Media is an effort to create a comprehensive archive of religous multimedia content.
                    </Paragraph>
            </PageContent>
        </Page>
    )
}