import { Zodios } from '@zodios/core';
import { speakerSchema, speakersSchema } from '../models/speaker';
import { locationSchema, locationsSchema } from '../models/location';
import { sermonSchema, sermonTableRowsSchema } from '../models/sermon';
import { mediaSchema, mediasSchema } from '../models/media';
import { z } from 'zod';
import { transcriptDocumentSchema, transcriptSchema, transcriptsSchema } from '../models/transcript';
import axios from 'axios';
import { ENV } from '../lib/env';

const baseurl = ENV.VITE_API_BASE_URL;

const client = axios.create({
  baseURL: baseurl,
  withCredentials: true
})

export const apiClient = new Zodios(baseurl, [
  {
    method: 'get',
    path: '/speakers',
    alias: 'getSpeakers',
    parameters: [
      {
        name: 'sermon',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: speakersSchema,
  },
  {
    method: 'get',
    path: '/speakers/:id',
    alias: 'getSpeaker',
    response: speakerSchema,
  },
  {
    method: 'get',
    path: '/locations',
    alias: 'getLocations',
    response: locationsSchema,
  },
  {
    method: 'get',
    path: '/locations/:id',
    alias: 'getLocation',
    response: locationSchema,
  },
  {
    method: 'get',
    path: '/views/sermons/table',
    alias: 'getSermonTable',
    response: sermonTableRowsSchema,
  },
  {
    method: 'get',
    path: '/sermons/:id',
    alias: 'getSermon',
    response: sermonSchema,
  },
  {
    method: 'get',
    'path': '/media/:id',
    alias: 'getMedia',
    response: mediaSchema
  },
  {
    method: 'get',
    path: '/media',
    alias: 'mediaSearch',
    parameters: [
      {
        name: 'sermon',
        type: 'Query',
        schema: z.string()
      }
    ],
    response: mediasSchema
  },
  {
    method: 'get',
    path: '/transcripts',
    alias: 'transcriptSearch',
    parameters: [
      {
        name: 'sermon',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'media',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: transcriptsSchema
  },
  {
    method: 'get',
    path: '/transcripts/:id',
    alias: 'getTranscript',
    response: transcriptSchema
    },
    {
      method: 'get',
      path: '/transcripts/:id/document',
      alias: 'getTranscriptDocument',
      response: transcriptDocumentSchema 
    }
], {
  axiosInstance: client
});
