import { PageHeader } from "grommet";
import { PropsWithChildren } from "react";

export interface PageProps {
    title: string
}

export default function Page({title, children}: PropsWithChildren<PageProps>) {
    return (
        <>
        <PageHeader title={title} />
        {children}
        </>
    )
}