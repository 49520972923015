import { ErrorBoundary } from "@suspensive/react"
import { AlertError } from "../ErrorBoundaries/AlertError"
import React from "react"
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner"

export type SuspenseBoundaryProps = {
    message: string;
    children?: React.ReactNode;
}


export function WrappedSuspense({message, children}: SuspenseBoundaryProps) {

    return (
        <>
        <ErrorBoundary fallback={<AlertError message={message} />}>
        <React.Suspense fallback={LoadingSpinner()}>
            {children}
            </React.Suspense>
        </ErrorBoundary>
        </>
    )
}