import { Box, Grommet, Header, Page, PageContent } from 'grommet';
import { PropsWithChildren } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BannerContextProvider } from './banners/BannerContext';
import { BannerDisplay } from './banners/BannerDisplay';
import { Banner } from './banners/banner';
import { AppFooter } from './directory/AppFooter';

const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

// Temporary banners while we're still in dev mode
const banners: Banner[] = [
  {
    id: '1',
    header: 'Warning',
    message: 'Data is for demonstation purposes only and may not have correct copyright assignment',
    status: 'critical',
    alignCenter: true,
  },
  {
    id: '2',
    header: 'Warning',
    message: 'APIs and data model are subject to change with no warning during development period',
    status: 'warning',
    alignCenter: true,
  },
];

export function AppLayout({ children }: PropsWithChildren<object>) {
  const navigate = useNavigate();
  return (
    <Grommet theme={theme} full>
      <BannerContextProvider initial={banners}>
        <Page fill="horizontal">
          <BannerDisplay />
          <Header
            background="brand"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation="medium"
            onClick={() => navigate('/')}
          >
            CRM
          </Header>
          <Box
            elevation="small"
            direction="row-responsive"
            pad="small"
            alignContent="center"
            gap="large"
            justify="center"
          >
            <Link to={`speakers`}>Speakers</Link>
            <Link to={`locations`}>Locations</Link>
            <Link to={`sermons`}>Sermons</Link>
          </Box>
          <PageContent height={{min: '81vh'}}>{children}</PageContent>
          <AppFooter />
        </Page>
      </BannerContextProvider>
    </Grommet>
  );
}
