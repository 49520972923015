import { z } from 'zod';

export const latLngSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export const addressSchema = z.object({
  line: z.string(),
  city: z.string(),
  state: z.string(),
  coordinate: latLngSchema.optional(),
});

export type Address = z.infer<typeof addressSchema>;

export const locationSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: addressSchema,
});

export const locationsSchema = z.array(locationSchema);

export type Location = z.infer<typeof locationSchema>;
