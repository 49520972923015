import { Box, Heading } from 'grommet';
import { SermonCardList } from '../components/SermonCard/SermonCardList';
import { QueryClient } from '@tanstack/react-query';
import { sermonTableQuery } from './queries';
import { WrappedSuspense } from '../components/SuspenseBoundary/SuspenseBoundary';

const homepageBorder = { size: 'medium', color: 'brand' };

export const loader = (queryClient: QueryClient) => async () => {
  const q = sermonTableQuery();
  return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
};
export function HomePage() {
  return (
    <Box>
      <Box
        direction="row"
        alignContent="between"
        justify="between"
        margin={{ top: 'medium' }}
        pad="small"
        border={[
          { side: 'bottom', ...homepageBorder },
        ]}
      >
        <Box pad="none" align="start">
          <Heading level={3} margin={'none'}>
            The
          </Heading>
          <Heading margin={'none'}>
            <i>Word of God</i>
          </Heading>
          <Heading level={3} margin={'none'}>
            through the
          </Heading>
          <Heading margin={'none'}>
            <i>People of God</i>
          </Heading>
        </Box>
        <Box />
        <Box></Box>
      </Box>
      <Box>
        <WrappedSuspense message="Failed to get recent sermons">
          <SermonCardList />
        </WrappedSuspense>
      </Box>
    </Box>
  );
}
