import { Box } from "grommet";
import { Media } from "../../models/media";
import { Ref } from "react";

const baseurl = import.meta.env.VITE_API_BASE_URL

export interface DisplayProps {
    audio: Media;
    audioRef: Ref<HTMLAudioElement>;
}

export function Display({audio, audioRef}: DisplayProps) {

    return <Box>
        <audio src={`${baseurl}/blob${audio.blob}`} ref={audioRef}/>
    </Box>;
}