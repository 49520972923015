import { z } from "zod";

export const churchSchema = z.object({
    id: z.string(),
    name: z.string(),
    denomination: z.string().nullable(),
    website: z.string().nullable()
});

export type Church = z.infer<typeof churchSchema>;

