import { TranscriptView } from "../Transcript/TranscriptView";
import { Duration } from "luxon";
import { Media } from "../../models/media";
import { Box } from "grommet";
import { WrappedSuspense } from "../SuspenseBoundary/SuspenseBoundary";

export interface MediaDetailProps {isDetail: boolean,
    media: Media,
    playhead?: Duration
}

export function MediaDetail({media, playhead}: MediaDetailProps) {


    return (
        <Box height={{min: '100vh'}}>
            <WrappedSuspense message="Unable to fetch transcript">
            <TranscriptView media={media} playhead={playhead} />
            </WrappedSuspense>
        </Box>
    )
}