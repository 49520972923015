import { Box, Heading, Text } from 'grommet';
import { FormClose, StatusCritical, StatusInfo, StatusWarning } from 'grommet-icons';
import React from 'react';
import { Banner } from './banner';

export type BannerProps = {
  banner: Banner;
  dismiss?: (id: string) => void;
};

export const BannerView: React.FC<BannerProps> = ({ banner, dismiss }) => {
  const { id, header, message, status, alignCenter } = banner;

  const icon = function () {
    switch (status) {
      case 'ok':
        return <StatusInfo />;
      case 'warning':
        return <StatusWarning />;
      case 'critical':
        return <StatusCritical />;
    }
  }();

  return (
    <Box fill data-testid='banner'>
      <Box
        pad="small"
        background={{ color: `status-${status}`, dark: true }}
        direction="row"
        align="center"
        justify="between"
      >
        { alignCenter && <Box />}
        <Box direction="row">
          {icon}
          <Heading level="4" margin={{left: 'xsmall', right: 'none', top: 'none', bottom: 'none'}} alignSelf="center">
            {header}:
          </Heading>
          <Text margin={{ left: 'xsmall' }}>{message}</Text>
        </Box>
        <FormClose size="medium" onClick={() => dismiss?.(id)} />
      </Box>
    </Box>
  );
};
