import { LoaderFunction, useParams } from 'react-router-dom';
import '../models/speaker';
import { QueryClient, useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { speakerDetailQuery } from './queries';
import { Avatar, Box, Heading, Paragraph } from 'grommet';

export const loader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ params }) => {
    invariant(params.id);
    const q = speakerDetailQuery(params.id);
    return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
  };

export default function Speaker() {
  const params = useParams();
  invariant(params.id);
  const { data } = useQuery(speakerDetailQuery(params.id));
  return (
    data && (
      <Box direction="column">
        <Box direction="row" gap="medium" border="bottom" alignContent="center">
          <Avatar src="//s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80" />
          <Box direction="column">
            <Heading level="3" margin="none">
              {data.firstName}
            </Heading>
            <Heading level="3" margin="none">
              {data.lastName}
            </Heading>
          </Box>
        </Box>
        <Box>
          <Paragraph>Here are some interesting things to know</Paragraph>
        </Box>
      </Box>
    )
  );
}
