import { Footer, Anchor, Text } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../lib/env';

export function AppFooter() {
  const navigate = useNavigate();
  return (
    <Footer background="brand" pad="small" justify="center">
      <Text>Copyright 2023 Centre for Religious Media</Text>
      {ENV.VITE_BUILD_COMMIT && (
        <>
          <Text size="small">Version: {ENV.VITE_BUILD_COMMIT}</Text>
        </>
      )}
      <Anchor label="About" onClick={() => navigate('/about')} />
    </Footer>
  );
}
