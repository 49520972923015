import { AgGridReact } from 'ag-grid-react';
import { Outlet } from 'react-router-dom';
import Page from './Page';
import { Box } from 'grommet';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export interface EntityPageProps<T> {
  title: string;
  clickHandler(event: RowClickedEvent<T>): void;
  query: UseQueryOptions<T[]>;
  columns: ColDef<T>[];
  defaultColumn?: ColDef<T>;
}

export function EntityPage<T>(props: EntityPageProps<T>) {
  const { data } = useQuery(props.query);

  const defaultColumnDef = useMemo(
    () =>
      props.defaultColumn ?? {
        sortable: true,
      },
    [props.defaultColumn],
  );

  return (
    <Page title={props.title}>
      <Box direction="row-responsive" gap="small">
        <Box width="70%">
          <div className="ag-theme-alpine" style={{ height: 500 }}>
            <AgGridReact<T>
              rowData={data}
              animateRows={true}
              onRowClicked={props.clickHandler}
              columnDefs={props.columns}
              defaultColDef={defaultColumnDef}
            />
          </div>
        </Box>
        <Box width="30%">
          <Outlet />
        </Box>
      </Box>
    </Page>
  );
}
