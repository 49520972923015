import { QueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { locationListQuery } from './queries';
import { useState } from 'react';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { Location } from '../models/location';
import { EntityPage } from '../components/EntityPage';

export const loader = (queryClient: QueryClient) => async () => {
  const q = locationListQuery();
  return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
};

export function LocationPage() {
  const navigate = useNavigate();

  const [columnDefs, _] = useState<ColDef<Location>[]>([
    { field: 'name', filter: true },
    { valueGetter: 'data.address.streetAddress', filter: true, sortable: false, headerName: 'Address'},
    { valueGetter: 'data.address.city', filter: true, headerName: 'City' },
    { valueGetter: 'data.address.state', filter: true, headerName: 'State' },
  ]);

  const rowClicked = (event: RowClickedEvent<Location>) => {
    if (event.data) {
      navigate(`/locations/details/${event.data.id}`);
    }
  };

  return EntityPage<Location>({
    title: 'Locations',
    clickHandler: rowClicked,
    columns: columnDefs,
    query: locationListQuery(),
  });
}
