import { z } from 'zod';

export const speakerSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
});

export const speakersSchema = z.array(speakerSchema);

export type Speaker = z.infer<typeof speakerSchema>;
