import { Duration } from "luxon";
import { z } from "zod";

const duration = z.string().transform((val) => Duration.fromISO(val));

const audioFormat = z.union([z.literal("WAV"), z.literal("MP3")]);

export type AudioFormat = z.infer<typeof audioFormat>; 

export const mediaSchema = z.discriminatedUnion("type", [
    z.object({
        type: z.literal("audio"),
        id: z.string(),
        blob: z.string(),
        duration: duration,
        channels: z.number().min(1).max(7),
        format: audioFormat
    })
]);

export const mediasSchema = z.array(mediaSchema);

export type Media = z.infer<typeof mediaSchema>;