import { apiClient } from '../services/api';

export const speakerListQuery = () => ({
  queryKey: ['speakers'],
  queryFn: async () => apiClient.getSpeakers(),
});

export const speakerDetailQuery = (id: string) => {
  return {
    queryKey: ['speaker', id],
    queryFn: async () => {
      return apiClient.getSpeaker({ params: { id } });
    },
  };
};

export const locationListQuery = () => ({
  queryKey: ['locations'],
  queryFn: async () => {
    return apiClient.getLocations();
  },
});

export const locationDetailQuery = (id: string) => ({
  queryKey: ['location', id],
  queryFn: async () => {
    return apiClient.getLocation({ params: { id } });
  },
});

export const sermonTableQuery = () => ({
  queryKey: ['sermons', 'table'],
  queryFn: async () => {
    return apiClient.getSermonTable();
  }
});

export const sermonDetailQuery = (id: string) => ({
  queryKey: ['sermon', id],
  queryFn: async () => {  
    return apiClient.getSermon({params: {id}})
  }
});

export const sermonMediaQuery = (id: string) => ({
  queryKey: ['sermon', id, 'media'],
  queryFn: async () => {
    return apiClient.mediaSearch({queries: {sermon: id}})
  }
});

export const sermonSpeakerQuery = (id: string) => ({
  queryKey: ['sermon', id, 'speakers'],
  queryFn: async () => {
    return apiClient.getSpeakers({queries: {sermon: id}})
  }
});

export const mediaQuery = (id: string) => ({
  queryKey: ['media', 'id'],
  queryFn: async () => {
    return apiClient.getMedia({params: {id}})
  }
});

export const mediaTranscriptDocumentQuery = (id: string) => ({
  queryKey: ['media', id, 'transcript'],
  queryFn: async () => {
   const results = await apiClient.transcriptSearch({queries: {media: id}}) 
   return apiClient.getTranscriptDocument({params: {id: results[0].id}})
  }
})
