import { useContext } from "react";
import BannerContext from "./BannerContext"
import { BannerView } from "./BannerView";
import { AnimatePresence, motion } from 'framer-motion';

export function BannerDisplay() {

    const context = useContext(BannerContext);
    return (
        <AnimatePresence>
        {context?.banners.map((banner, idx) => (<motion.div key={idx} animate={{opacity: 1.0}} exit={{opacity: 0.0}}>
            <BannerView banner={banner} dismiss={context?.removeBanner} />
            </motion.div>)
        )}
        </AnimatePresence>
    )
}