import { z } from "zod";

const envSchema = z.object({
    VITE_API_BASE_URL: z.string(),
    VITE_SENTRY_ENV: z.string().optional(),
    VITE_SENTRY_DSN: z.string().optional(),
    VITE_SENTRY_SAMPLE_RATE: z.coerce.number().optional(),
    VITE_BUILD_COMMIT: z.string().optional()
});

export const ENV = envSchema.parse(import.meta.env);