import { Box, Clock, RangeInput } from "grommet";
import { ChangeEvent, useMemo } from "react";
import { Media } from "../../models/media";
import { Duration } from "luxon";

export interface ProgressBarProps {
    media: Media;
    playhead: Duration;
    setPlayhead: (time: Duration) => void;
}

export function ProgressBar({media, playhead, setPlayhead}: ProgressBarProps) {
    const [maxValue, maxTime] = useMemo(() => {
        // This is where we set the max time value per media entry;
        const seconds = media.duration.shiftTo("seconds").seconds;
        const maxString = media.duration.toString();
        return [seconds, maxString];
    }, [media]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        console.log("Event: ", event);
        setPlayhead(Duration.fromObject({seconds: Number.parseInt(event.target.value)}));
    };
    return <Box gap="small" direction="row" margin="none">
        <Clock precision="seconds" time={playhead.shiftToAll().toString()!} type="digital" run={false} size="small" />
        <RangeInput value={playhead.shiftTo("seconds").seconds} min={0} max={maxValue + 1000} width="50%" onChange={onChange} />
        <Clock precision="seconds" time={maxTime!} type="digital" run={false} size="small"/>
    </Box>;
}