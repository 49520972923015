import { ColDef, RowClickedEvent } from "ag-grid-community";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SermonTableRow } from "../models/sermon";
import { EntityPage } from "../components/EntityPage";
import { QueryClient } from "@tanstack/react-query";
import { sermonTableQuery } from "./queries";

export const loader = (queryClient: QueryClient) => async () => {
    const q = sermonTableQuery();
    return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
}

export function SermonListPage() {
    const navigate = useNavigate();

    const [columnDefs] = useState<ColDef<SermonTableRow>[]>([
        { field: 'title', filter: true},
        { field: 'delivered', filter: false},
        { headerName: 'Speaker', valueGetter: params => {
            return params.data?.deliveredBy?.firstName + " " + params.data?.deliveredBy?.lastName;
        }},
        {
            headerName: 'Church', valueGetter: params => {
                return params.data?.deliveredAt?.name;
            }
        }
    ]);

    const rowClicked = (event: RowClickedEvent<SermonTableRow>) => {
        if (event.data) {
            navigate(`/sermons/${event.data.id}?detail=true`);
        }
    }

    return EntityPage<SermonTableRow>({
        title: 'Sermons',
        clickHandler: rowClicked,
        columns: columnDefs,
        query: sermonTableQuery(),
    });
}