import { QueryClient, useQuery } from '@tanstack/react-query';
import { LoaderFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { sermonDetailQuery, sermonSpeakerQuery } from './queries';
import invariant from 'tiny-invariant';
import {
  Avatar,
  Box,
  Button,
  Heading,
  NameValueList,
  NameValuePair,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MediaList } from '../components/Media/MediaList';
import { MediaDetail } from '../components/Media/MediaDetail';
import { Duration } from 'luxon';
import { Media } from '../models/media';
import { WrappedSuspense } from '../components/SuspenseBoundary/SuspenseBoundary';
import { Share } from 'grommet-icons';

export const loader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ params }) => {
    invariant(params.id);
    const q = sermonDetailQuery(params.id);
    return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
  };

export function SermonPage() {
  const navigate = useNavigate();
  const params = useParams();
  invariant(params.id);
  const { data: sermon } = useQuery(sermonDetailQuery(params.id));
  const { data: speaker } = useQuery(sermonSpeakerQuery(params.id));

  const handleNavigation = () => navigate(`/sermon/${params.id}`);

  const [isDetail, setIsDetail] = useState(true);
  const [autoPlay, setAutoplay] = useState(false);
  const location = useLocation();

  const [currentAudio, setCurrentAudio] = useState<Media>();
  const [playhead, setCurrentPlayHead] = useState<Duration>();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isDetailView = queryParams.get('detail');
    if (isDetailView) {
      setIsDetail(isDetailView == 'true');
    } else {
      setIsDetail(false);
    }

    const isAutoPlay = queryParams.get('autoPlay');
    if (isAutoPlay) {
      setAutoplay(isAutoPlay == 'true');
    } else {
      setAutoplay(false);
    }
  }, []);

  const responsiveSize = useContext(ResponsiveContext);

  const pageLayout = useMemo(() => {
    return responsiveSize === 'small' ? 'column' : 'row';
  }, [responsiveSize]);

  return (
    sermon && (
      <Box direction={pageLayout}>
        <Box direction="column" height="xlarge">
          <Box direction="row" gap="medium" border="bottom" alignContent="center" pad={{ bottom: 'small' }}>
            <Avatar src="//s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80" />
            <Box direction="column" alignContent="center">
              <Heading level="3" margin="none">
                {sermon.title}
              </Heading>
              <Heading level="4" margin="none">
                {sermon.delivered}
              </Heading>
            </Box>
            <Box direction="column" justify="end" alignContent="top">
              {isDetail && <Button icon={<Share />} color="brand" onClick={handleNavigation} />}
            </Box>
          </Box>
          <Box border="bottom">
            <Paragraph>This is a very interesting sermon</Paragraph>
          </Box>
          <Box pad={{ top: 'small' }}>
            <NameValueList>
              <NameValuePair name="Speaker">
                <Text color="text-strong">{speaker && `${speaker[0].firstName} ${speaker[0].lastName}`}</Text>
              </NameValuePair>
              <NameValuePair name="Church">
                <Text color="text-strong">I'm a church</Text>
              </NameValuePair>
            </NameValueList>
          </Box>
          {!isDetail && (
            <Box border="top">
              <WrappedSuspense message="Unable to fetch media">
                <MediaList
                  autoPlay={autoPlay}
                  id={params.id}
                  onSelect={setCurrentAudio}
                  media={currentAudio}
                  onPlayheadUpdate={setCurrentPlayHead}
                />
              </WrappedSuspense>
            </Box>
          )}
        </Box>
        {!isDetail && (
          <Box fill={true}>
            {currentAudio && <MediaDetail isDetail={isDetail} media={currentAudio} playhead={playhead} />}
          </Box>
        )}
      </Box>
    )
  );
}
