import * as Sentry from '@sentry/react';
import { ENV } from './env';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';
import { CaptureConsole } from '@sentry/integrations';

export const initSentry = () => {
    Sentry.init({
        dsn: ENV.VITE_SENTRY_DSN,
        tracePropagationTargets: ["localhost", /^https:\/\/crm(-api)?\.nickrobison\.com\//],
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            }),
            new CaptureConsole()
        ],
        tracesSampleRate: ENV.VITE_SENTRY_SAMPLE_RATE,
        environment: ENV.VITE_SENTRY_ENV
    });
};

export const createSentryBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);