import { Box } from 'grommet';
import { SermonCard } from './SermonCard';
import { WrappedSuspense } from '../SuspenseBoundary/SuspenseBoundary';
import { sermonTableQuery } from '../../pages/queries';
import { useSuspenseQuery } from '@suspensive/react-query';

export function SermonCardList() {
  const { data } = useSuspenseQuery(sermonTableQuery());
  return (
    <Box direction="row-responsive" overflow="scroll" wrap={true} gap="medium">
      {data.map(({ id }, _) => (
        <Box key={id} width={{min: 'small'}}>
        <WrappedSuspense message="Failed to get sermon">
          <SermonCard key={id} id={id} />
        </WrappedSuspense>
        </Box>
      ))}
    </Box>
  );
}
