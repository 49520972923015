import { Box } from 'grommet';
import { Controls } from './Controls';
import { ProgressBar } from './ProgressBar';
import { Display } from './Display';
import { Media } from '../../models/media';
import { useEffect, useRef, useState } from 'react';
import { Duration } from 'luxon';

export interface AudioPlayerProps {
  audio?: Media;
  onPlayheadUpdate?: (time: Duration) => void;
  autoPlay: boolean;
}

export function AudioPlayer({ audio, onPlayheadUpdate, autoPlay }: AudioPlayerProps) {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [playhead, setPlayhead] = useState<Duration>(Duration.fromMillis(0));

  useEffect(() => {
    if (onPlayheadUpdate) {
      onPlayheadUpdate(playhead);
    }
  }, [playhead]);

  const handleTimeUpdate = (value: Duration) => {
    if (audioRef.current) {
      console.debug('Setting playhead manually', value.shiftTo('seconds').seconds, audioRef.current.currentTime);
      audioRef.current.currentTime = value.shiftTo('seconds').seconds;
    }
  };

  return (
    <Box direction="column" gap="medium" align="center" height={{ min: 'xsmall' }}>
      {audio && (
        <>
          <Display audio={audio} audioRef={audioRef} />
          <ProgressBar media={audio} playhead={playhead} setPlayhead={handleTimeUpdate} />
          <Controls audioRef={audioRef} onUpdate={setPlayhead} onSet={handleTimeUpdate} autoPlay={autoPlay} />
        </>
      )}
    </Box>
  );
}
