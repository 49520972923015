import { motion } from "framer-motion";
import { Box, Spinner } from "grommet";

export function LoadingSpinner() {

    return(
        <Box align="center" fill={true} align-content="center"  pad={{top: 'medium'}}>
            <motion.div initial={{opacity: 0.0}} animate={{opacity: 1.0}}>
            <Spinner size="large" />
            </motion.div>
            
        </Box>
    )
}